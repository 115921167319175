<template>
  <article
    style="margin-bottom: 5px"
    :class="[
      $style.version_entry,
      index === 0 ? $style.version_entry__newest : null,
    ]"
  >
    <span
      :style="
        version.publishedat && version.id == newestLive
          ? 'color:hsl(141, 53%, 53%)'
          : ''
      "
    >
      <fa icon="file" />
    </span>
    <div :class="$style.version_entry__info">
      <header class="level">
        <div>
          <h4
            :style="
              version.publishedat && version.id == newestLive
                ? 'color:hsl(141, 53%, 53%)'
                : ''
            "
          >
            Version {{ count - index }} (ID: {{ version.id }})
          </h4>
        </div>
        <div v-if="!hasEditorVersionTwo">
          <rg-button
            v-if="!version.readonly || newestLive == version.id"
            icon-left="copy"
            label="Duplizieren"
            @click="
              $emit(
                'duplicate',
                newestLive == version.id ? { slug } : { hash: version.id }
              )
            "
          />
          <rg-button
            v-if="
              $store.state.user.tier === 'administration' && !version.readonly
            "
            icon-left="upload"
            class="is-danger"
            style="margin-left: 3px"
            label="LIVE"
            @click="$emit('sendlive', version.id)"
          />
        </div>
      </header>
      <br>
      <div class="level">
        <span style="flex: 1">
          <fa
            class="fa-fw"
            :icon="version.updatedat ? 'calendar-alt' : 'calendar-times'"
          />
          <span>{{
            version.updatedat
              ? new Date(version.updatedat).toLocaleString()
              : "älter als 20.4.2020"
          }}</span>
        </span>
        <span style="flex: 1">
          <fa
            class="fa-fw"
            :icon="version.author ? 'user' : 'user-ninja'"
          />&nbsp;
          {{ version.author || "Autor unbekannt" }}
        </span>
      </div>
      <div class="level">
        <span style="flex: 1">
          <span
            :style="
              version.publishedat && version.id == newestLive
                ? 'color:hsl(141, 53%, 53%)'
                : ''
            "
          >
            <fa
              class="fa-fw"
              icon="circle"
            />&nbsp; Live seit
            {{
              version.publishedat
                ? new Date(version.publishedat).toLocaleString()
                : "-"
            }}
          </span>
        </span>
      </div>
      <hr>
      <div class="flex-right">
        <router-link
          :to="preview"
          target="_blank"
        >
          <rg-button
            icon="eye"
            title="Rep.Guide Vorschau"
          />
        </router-link>
        <router-link
          v-if="!version.readonly && version.editor_version !== 'v2'"
          :to="link"
          style="margin: 0 5px"
        >
          <rg-button
            type="is-primary"
            icon-left="pen"
            label="Bearbeiten"
          />
        </router-link>
        <a
          v-if="!version.readonly && canEditV2"
          :href="getNewAdminToolLink(version.id)"
          target="_blank"
          style="margin: 0 5px"
        >
          <rg-button
            type="is-primary"
            icon-left="pen"
            icon-right="external-link-alt"
            label="Bearbeiten (Neuer Editor)"
          />
        </a>
        <rg-button
          v-if="version.readonly || (version.editor_version === 'v2' && !canEditV2)"
          style="margin: 0 5px"
          disabled
          type="is-danger"
          icon-left="times"
          label="Nicht verfügbar"
        />
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    newestLive: { type: Number, default: 0 },
    hasEditorVersionTwo: { type: Boolean, default: false },
    version: { type: Object, default: () => {} },
    versions: { type: Object, default: () => {} },
    index: { type: Number, default: 0 },
    link: { type: String, default: "" },
    preview: { type: String, default: "" },
    slug: { type: String, default: "" },
    live: { type: Object, default: () => {} },
    count: { type: Number, default: 0 },
  },
  setup(props, context) {
    return {
      // NOTE: Sebastian, Helge, Finn, Jan
      canEditV2: [1, 4, 6, 500, 503, 504, 3130]
        .includes(context.root.$store.state.user.id),
      getNewAdminToolLink: (id) => {
        // const LIVE_URL = "https://rep.guide/de/admin/";
        const PREVIEW_URL = "https://finn-vgtl-techdata-input-mig.rg-whitelabel.pages.dev/";
        return `${PREVIEW_URL}de/admin/techdata/${id}`
      }
    };
  }
};
</script>

<style lang="scss" module>
.version_entry {
  display: grid;
  grid-template-columns: 60px auto;
  text-decoration: none;
  background: var(--card-light-stack);
  border-radius: 6px;
  padding: 10px 20px 10px 10px;
  > span {
    font-size: 2.8em !important;
  }
}
.version_entry__info__live {
  background: red;
  border-radius: 10px;
  padding: 0 10px;
  letter-spacing: 1px;
  box-shadow: 0 4px 8px rgb(255 0 0 / 0.3);
  text-decoration: none;
  color: white;
}
.version_entry__info {
  b {
    padding: 15px 5px;
    font-size: 1.1rem;
  }
  div {
    user-select: none;
    color: var(--text-dark);
    hr {
      display: block;
      height: 20px;
      width: 2px;
      margin: 0;
      color: var(--text-dark);
    }
    > span {
      display: inline-flex;
      align-items: center;
      span:first-of-type {
        margin-right: 5px;
      }
    }
  }
}
</style>